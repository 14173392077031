import React from 'react'
import img4 from '../assets/icons/Mapping.svg'
import img2 from '../assets/icons/Videography.svg'
import Card from '../components/Card'

export default function About() {
    const data = [

        {
            title: "Videography",
            description: 'Use a Videography Drone to Capture Memorable Moments at Events.',
            icon: img2,
            status: "Know More "
        },
        // {
        //     title: "Surveillance",
        //     description: 'Keep an eye on the condition of the building / bridges / assets at your convenience.',
        //     icon: img3,
        //     status: "Stay Tuned..."
        // },
        {
            title: "Land Survey / Mapping",
            description: 'Use a drone for surveying and mapping to obtain data quickly.',
            icon: img4,
            status: "Stay Tuned..."
        },
        // {
        //     title: "Agriculture",
        //     description: 'Drones can be used in agriculture to fill in the gaps left by human error and inefficiency.',
        //     icon: img1,
        //     status: "Stay Tuned..."
        // },
    ]
    return (
        <section className="about p-2 py-3">
            <div className="container">

                <div style={{ color: '#151D41' }} className="col-12 ps-2 h2 my-3 text-md-center fw-bold">
                    What's in it for you
                </div>
                <div style={{ color: '#151D41' }} className="h5 ps-2 text-md-center">Fly is a smart mobile application that helps you to get drone and drone pilots for</div>
                <br />
                <div className="row justify-content-center mt-4">
                    {data.map((x, i) => <div key={i} className='col-12 col-md-6 p-3'><Card type='about' data={x} /></div>)}
                </div>

            </div>
        </section>
    )
}
