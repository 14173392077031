// import { faBarsStaggered } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import logo from '../assets/White.png'

export default function Navbar() {

   // const handleClick = () => {
   //     const x = document.querySelector('.menu-items');
   //     x.classList.toggle("active")
   // }

   return (
      <header style={{ backgroundColor: "#000" }}>

         <div className="container py-3 d-flex justify-content-between align-items-center">

            <a className='d-flex align-items-center' href="https://soaron.co">
               <img id='logo' src={logo} alt="" />
            </a>



            {/* <div className="d-none d-md-flex align-items-center fs-5">
                    <div><a className="navlink py-1 px-3" href="https://soaron.notion.site/How-the-fly-idea-was-born-f5dae1fb30cd411fa5099af734409b27">Story</a> </div>
                    <div><a className="navlink py-1 px-3" href="https://soaron.notion.site/Career-07ae750922a64c158b5c4ca563eca176">Careers</a> </div>
                    <div><a className="navlink py-1 ps-3" href="https://forms.gle/d9QwTYK9tGHP56e38">Partner With Us</a> </div>
                </div>

                <div onClick={handleClick} className="menu-icon d-flex align-items-center d-md-none">
                    <FontAwesomeIcon size='lg' icon={faBarsStaggered} />
                </div> */}

         </div>
      </header>
   )
}
