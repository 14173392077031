import React from 'react'
import img1 from '../assets/icons/download.svg'
import img2 from '../assets/icons/signup.svg'
import img3 from '../assets/icons/searchcategory.svg'
import img5 from '../assets/icons/bookdrone.svg'

export default function QuickTap() {
    return (
        <section className="quick-taps py-4 p-2 mb-4" >
            <div className="container px-0">
                <div style={{ color: '#151D41' }} className="col-12 h2 my-3 text-center fw-bold">
                    Ready, set, go in just a few quick taps
                </div>
                <br />
                <div className='container px-3'>
                    <div className="d-flex justify-content-between">
                        <div style={{
                            width: "25%",
                            display: "flex"
                        }}>
                            <div style={{
                                width: "70%"
                            }}>

                                <img style={{ width: "100%" }} src={img1} alt="" />
                            </div>
                            <hr style={{
                                width: "31%",
                                margin: "auto",
                                height: "2%",
                                opacity: 0.7
                            }} />
                        </div>

                        <div style={{
                            width: "25%",
                            display: "flex"
                        }}>
                            <hr style={{
                                width: "10%",
                                margin: "auto",
                                height: "2%",
                                opacity: 0.7
                            }} />
                            <img style={{ width: "70%" }} src={img2} alt="" />
                            <hr style={{
                                width: "21%",
                                margin: "auto",
                                height: "2%",
                                opacity: 0.7
                            }} />
                        </div>

                        <div style={{
                            width: "25%",
                            display: "flex"
                        }}>
                            <hr style={{
                                width: "20%",
                                margin: "auto",
                                height: "2%",
                                opacity: 0.7
                            }} />
                            <img style={{ width: "70%" }} src={img3} alt="" />
                            <hr style={{
                                width: "11%",
                                margin: "auto",
                                height: "2%",
                                opacity: 0.7
                            }} />
                        </div>

                        {/* <div style={{
                            width: "25%",
                            display: "flex"
                        }}>
                            <hr style={{
                                width: "15%",
                                margin: "auto",
                                height: "2%",
                                opacity: 0.7
                            }} />
                            <img style={{ width: "70%" }} src={img4} alt="" />
                            <hr style={{
                                width: "6%",
                                margin: "auto",
                                height: "2%",
                                opacity: 0.7
                            }} />
                        </div> */}

                        <div style={{
                            width: "25%",
                            display: "flex"
                        }}>
                            <hr style={{
                                width: "30%",
                                margin: "auto",
                                height: "2%",
                                opacity: 0.7
                            }} />
                            <img style={{ width: "70%" }} src={img5} alt="" />

                        </div>
                    </div>
                </div>


                <div className='container px-2 mt-3'>
                    <div className="d-flex justify-content-between">
                        <div style={{
                            width: "17.5%",
                        }}>
                            <div className="h5 text-center quick-texts">Download App</div>
                        </div>
                        <div style={{
                            width: "17.5%",
                        }}>
                            <div className="h5 text-center quick-texts">Sign Up</div>
                        </div>
                        <div style={{
                            width: "17.5%",
                        }}>
                            <div className="h5 text-center quick-texts">Search Category</div>
                        </div>
                        <div style={{
                            width: "17.5%",
                        }}>
                            <div className="h5 text-center quick-texts">Book Drone</div>
                        </div>

                    </div>
                </div>

                <br />
            </div>
        </section>
    )
}
