import React from "react";
import {
    faInstagram,
    faLinkedin,
    faXTwitter,
    faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { links } from "../constant";
import { faCopyright } from "@fortawesome/free-regular-svg-icons";

export default function Footer() {
    return (
        <footer id="footer" className="py-4">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-6  col-md-5 py-2 text-md-center">
                        <div className="gap-4 m-auto">
                            <ul className="p-0  address">
                                <div className="my-2">
                                    <a href="https://soaron.notion.site/About-Us-26bae4da9318487f92825f43f323c64c">
                                        About Us
                                    </a>
                                </div>
                                <div className="my-2">
                                    <a href="https://soaron.notion.site/Flytor-FAQ-f47d3a9e82174d0fb67719bca35b34ce">
                                        Flytor FAQ
                                    </a>
                                </div>
                                <div className="my-2">
                                    <a href="https://chat.whatsapp.com/IVLknaolSCt0LMENVVTxNV">
                                        Flytor Network
                                    </a>
                                </div>
                                <div className="my-2">
                                    <a href="https://soaron.notion.site/Help-Support-58ce8ccd4676498c8240e2ac1872ca0e">
                                        Help & Support
                                    </a>
                                </div>
                            </ul>
                        </div>
                    </div>
                    <div className="col-6 px-0 col-md-5 py-2 text-md-center">
                        <div className="gap-4 m-auto">
                            <ul className="p-0  address ">
                                <div className="my-2">
                                    <a href="https://soaron.notion.site/Terms-Conditions-9082d462848542babbf8ca26a53e8415">
                                        Terms & Conditions
                                    </a>
                                </div>
                                <div className="my-2">
                                    <a href="https://soaron.notion.site/Privacy-Policy-f4ad6158061545a18f8270d6a49e8ee0">
                                        Privacy Policy
                                    </a>
                                </div>
                                <div className="my-2">
                                    <a href="https://soaron.notion.site/Refund-Cancellation-Return-Shipping-2c98fbb14b63472f8aef085fc10a3f36">
                                        Refund & Others
                                    </a>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="d-md-flex flex-row-reverse justify-content-between">
                    <div className="social-icons d-flex list-style-none justify-content-center mb-2">
                        <div>
                            <a
                                target={"_blank"}
                                rel="noreferrer"
                                className="linkedin"
                                href={links.linkedin}>
                                <i>
                                    <FontAwesomeIcon
                                        className="light"
                                        icon={faLinkedin}
                                    />
                                </i>
                            </a>
                        </div>
                        <div>
                            <a
                                target={"_blank"}
                                rel="noreferrer"
                                className="twitter"
                                href={links.twitter}>
                                <i>
                                    <FontAwesomeIcon
                                        className="light"
                                        icon={faXTwitter}
                                    />
                                </i>
                            </a>
                        </div>
                        <div>
                            <a
                                target={"_blank"}
                                rel="noreferrer"
                                className="instagram"
                                href={links.instagram}>
                                <i>
                                    <FontAwesomeIcon
                                        className="light"
                                        icon={faInstagram}
                                    />
                                </i>
                            </a>
                        </div>
                        <div>
                            <a
                                target={"_blank"}
                                rel="noreferrer"
                                className="youtube"
                                href={links.youtube}>
                                <i>
                                    <FontAwesomeIcon
                                        className="light"
                                        icon={faYoutube}
                                    />
                                </i>
                            </a>
                        </div>
                    </div>
                    <div
                        style={{ color: "#151D41" }}
                        className="copyright text-center ">
                        {" "}
                        Copyright <FontAwesomeIcon size="sm" icon={faCopyright} /> Soaron All Right Reserved.{" "}
                    </div>
                </div>
            </div>
        </footer>
    );
}
