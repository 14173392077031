export const links = {
    fly_android:
        "https://play.google.com/store/apps/details?id=com.soaron.fly2",
    fly_ios: "https://apps.apple.com/us/app/fly-soaron/id6449644308",
    flytor_android:
        "https://play.google.com/store/apps/details?id=com.soaron.flytor",
    flytor_ios: "https://apps.apple.com/app/flytor/id6444050069",
    twitter: "https://twitter.com/fly_flytor",
    instagram: "https://www.instagram.com/fly_flytor/",
    linkedin: "https://www.linkedin.com/showcase/fly-flytor",
    youtube: "https://youtube.com/@Fly_Flytor",
};
