import Aos from "aos";
import 'aos/dist/aos.css';
import { useEffect } from "react";
import NavMenu from "./components/NavMenu";
import About from "./sections/About";
import Earn from "./sections/Earn";
import Footer from "./sections/Footer";
import Hero from "./sections/Hero";
import Navbar from "./sections/Navbar";
import QuickTap from "./sections/QuickTap";
import Services from "./sections/Services";

function App() {
    useEffect(() => {
        Aos.init({
            once: true,
            duration: 800
        })
    }, []);

    return (
        <>
            <Navbar />
            <NavMenu />
            <Hero />
            <About />
            <Services />
            <QuickTap />
            <Earn />
            <Footer />
        </>
    );
}

export default App;
