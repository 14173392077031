import React from 'react'

export default function NavMenu() {
    return (
        <div className="nav">
            <div className=" menu-items d-md-none align-items-center gap-5 text-light">
                <div><a className="item text-dark" href="https://soaron.notion.site/How-the-fly-idea-was-born-f5dae1fb30cd411fa5099af734409b27">Story</a> </div>
                <div><a className="item text-dark" href="https://soaron.notion.site/Career-07ae750922a64c158b5c4ca563eca176">Careers</a> </div>
                {/* <div><a className="item text-dark" href="https://soaron.notion.site/933dd67df1ca4f09a65cff1a397bb426?v=ac1f9f3be98e49868180dff4b07dcd71">Blogs</a> </div> */}
                <div><a className="item text-dark" href="https://forms.gle/d9QwTYK9tGHP56e38">Partner With Us</a> </div>
            </div>
        </div>
    )
}
