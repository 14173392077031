import { faMinus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import Popup from './Popup'

export default function Card({ type, data }) {

    const [open, setOpen] = useState(false)

    const style = {
        aboutCard: {
            boxShadow: '0px 20px 20px #7682B72E',
            width: '100%',
        },
        serviceCard: {
            boxShadow: '0px 20px 20px #7682B72E',
            width: '100%',
        }
    }

    switch (type) {
        case 'about':
            return (<>
                <Popup content="Coming soon..." show={open} onHide={() => setOpen(false)} />
                <div style={style.aboutCard} className="aboutCard gap-2 gap-md-4 d-flex flex-column justify-content-around p-4 p-md-5 rounded" >
                    <div className="icon">
                        <img className='m-0' src={data.icon} width={90} alt="" />
                    </div>
                    <div>

                        <div className="h2" style={{ color: '#151D41' }}>
                            {data.title}
                        </div>

                        <div style={{ color: '#151D41' }}>
                            <FontAwesomeIcon className='mx-2' size='xl' icon={faMinus} />
                            <FontAwesomeIcon className='mx-2' size='xl' icon={faMinus} />
                            <FontAwesomeIcon className='mx-2' size='xl' icon={faMinus} />
                        </div>

                    </div>

                    <div className="h6" style={{ color: '#151D41' }}>
                        {data.description}
                    </div>

                    {/* <div className='my-2' >
                        {
                            data.status === "Stay Tuned..." ? <div className='py-2 px-4 text-light' style={{
                                width: 150,
                                backgroundColor: "#565656"
                            }}>Stay Tuned...</div> :
                                <button onClick={() => setOpen(true)} className="b-btn py-2 px-4 my-2" style={{ backgroundColor: '#323232' }} >
                                    {data.status}
                                </button>
                        }

                    </div> */}
                </div>
            </>

            )

        case 'service':
            return (
                <div style={style.serviceCard} className="serviceCard d-flex flex-column justify-content-around p-3 p-md-5 rounded" >
                    <div className="icon">
                        <img className='m-0' src={data.icon} width={85} alt="" />
                    </div>
                    <div style={{
                        backgroundColor: '#e7e7ee'
                    }}>
                        <div className="" style={{
                            height: '5px',
                            backgroundColor: '#151D41',
                            width: '80%'
                        }}></div>
                    </div>
                    <div style={{ color: '#151D41' }} className="h5 my-3 fw-bold">
                        {data.title}
                    </div>
                </div>
            )


        default:
            return null
    }



}
