import React, { useState } from 'react'
import heroImg from '../assets/banner.png'
import Popup from '../components/Popup'
import { links } from '../constant'

export default function Hero() {
    const [open, setOpen] = useState(false)
    return (
        <>
            <section className="hero p-4 ps-2 ps-md-4 px-2 py-5 pt-md-4" style={{ height: '95vh' }}>

                <br className="d-none d-md-block" />

                <div className="container ">
                    <div className="justify-content-start row">

                        <div className="col-md-6 col-12 text-md-start d-flex flex-column gap-5 justify-content-center">
                            <div className='d-flex flex-column gap-1 justify-content-center'>
                                <div data-aos="fade-up" className="heading fw-bold  mb-2">Book a Drone Easily</div>
                                <div data-aos="fade-up" className="h4">Efficient, Quick & Accessible on Soaron Fly Mobile App</div>
                                <br />
                            </div>

                            <div className='d-none d-md-block'>

                                <div data-aos="fade-up" className="h5 mb-3" style={{ color: '#000000BF' }}>Download the App</div>

                                <div className="d-none d-md-flex justify-content-md-start ">
                                    <a target={'_blank'} rel='noreferrer' className='b-btn py-3 px-5  me-4' href={links.fly_android}>ANDROID</a>
                                    <a target={'_blank'} rel='noreferrer' className='b-btn py-3 px-5' href={links.fly_ios}>IPHONE</a>
                                    {/* <div role='button' className='b-btn py-3 px-5 me-4' onClick={() => setOpen(true)} >ANDROID</div> */}
                                    {/* <div role='button' className='b-btn py-3 px-5' onClick={() => setOpen(true)} >IPHONE</div> */}
                                </div>
                                <br />
                            </div>

                        </div>
                        <div className="col-1"></div>
                        <div data-aos="fade-up" className="col-md-5 col-12">
                            <img className='hero-img' width={'105%'} src={heroImg} alt="" />
                        </div>
                        {/* For mobile view */}
                        <div className='h-50 d-block d-md-none'>
                            <br />
                            <br />
                            <div data-aos="fade-up" className="h5 mb-3">Download the App</div>
                            <div className="d-flex justify-content-md-start ">
                                <a target={'_blank'} rel='noreferrer' className=' b-btn py-2 px-4 me-3' href={links.fly_android}>ANDROID</a>
                                <a target={'_blank'} rel='noreferrer' className=' b-btn py-2 px-4' href={links.fly_ios}>IPHONE</a>
                                {/* <div role='button' className='b-btn py-2 px-4 me-3' onClick={() => setOpen(true)} >ANDROID</div> */}
                                {/* <div role='button' className='b-btn py-2 px-4' onClick={() => setOpen(true)} >IPHONE</div> */}
                            </div>
                            <br />
                        </div>

                    </div>
                </div>
                <br />
                <Popup content="The Fly app on iOS is coming soon." show={open} onHide={() => setOpen(false)} />
            </section>
        </>

    )
}
