import React from 'react'
import Card from '../components/Card'
import img1 from '../assets/icons/Verified.svg'
import img2 from '../assets/icons/Cancel.svg'
import img3 from '../assets/icons/Service.svg'
import img4 from '../assets/icons/Delivery.svg'

export default function Services() {
    const data = [
        {
            title: "Verified drones and pilots",
            icon: img1
        },
        {
            title: "It is easy to book and cancel",
            icon: img2
        },
        {
            title: "Quick service at a good price",
            icon: img3
        },
        {
            title: "On-time delivery at the location",
            icon: img4
        },
    ]
    return (
        <section className="services p-2 py-5">
            <div className="container">

                <div style={{ color: '#151D41' }} className="col-12 h2 my-3 ps-2 text-md-center fw-bold">
                    So, why choose us?
                </div>

                <div className="row justify-content-center mt-4">
                    {data.map((x, i) => <div key={i} className='col-12 col-md-6 p-3'><Card type='service' data={x} /></div>)}
                </div>

            </div>
            <br />
        </section>
    )
}
